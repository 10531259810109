<template>
    <order-detail
        :path="[{title:$t('reviews.title'),link:'reviews'}]"
    />
</template>

<script>

import OrderDetail from "@/views/pages/orders/detail";

export default {
  components: {
    OrderDetail,
  },

};
</script>

